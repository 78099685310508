.no-scroll {
  overflow: hidden;
  position: relative;
}

.pintura-dark {
  --color-background: 0, 0, 0;
  --color-foreground: 255, 255, 255;
}

@font-face {
font-family: '__SatoshiVariable_66a588';
src: url(/_next/static/media/d9396795aa5ec363-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__SatoshiVariable_Fallback_66a588';src: local("Arial");ascent-override: 92.36%;descent-override: 21.95%;line-gap-override: 9.14%;size-adjust: 109.35%
}.__className_66a588 {font-family: '__SatoshiVariable_66a588', '__SatoshiVariable_Fallback_66a588'
}

